define('ember-share-buttons/components/share-buttons', ['exports', 'ember', 'ember-service/inject', 'ember-share-buttons/templates/components/share-buttons', 'jquery'], function (exports, _ember, _emberServiceInject, _emberShareButtonsTemplatesComponentsShareButtons, _jquery) {
  var computed = _ember['default'].computed;

  var defaultNetworks = ['pinterest', 'twitter', 'facebook', 'whatsapp', 'googlePlus', 'reddit', 'linkedin', 'email'];

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['share-buttons'],
    fastboot: (0, _emberServiceInject['default'])(),
    isFastBoot: computed.readOnly('fastboot.isFastBoot'),
    layout: _emberShareButtonsTemplatesComponentsShareButtons['default'],
    config: null,
    tagName: 'ul',

    didReceiveAttrs: function didReceiveAttrs() {
      var config = this.get('config');
      var networks = undefined;
      if (config && config.networks) {
        networks = config.networks.map(expandNetworkConfig).filter(function (n) {
          return defaultNetworks.indexOf(n.name) !== -1;
        });
      } else {
        networks = defaultNetworks.map(expandNetworkConfig);
      }
      this.set('networks', networks);
    },

    pageTitle: computed('ogTitle', function () {
      if (!this.get('isFastBoot')) {
        return this.get('ogTitle') || this.title();
      }
    }).volatile(),

    pageDescription: computed('ogDescription', function () {
      if (!this.get('isFastBoot')) {
        return this.get('ogDescription') || this.description();
      }
    }).volatile(),

    pageUrl: computed(function () {
      if (!this.get('isFastBoot')) {
        return this.url();
      }
    }).volatile(),

    shareActions: {
      facebook: function facebook(config) {
        if (config.appId == null || !window.FB) {
          openPopup('https://www.facebook.com/sharer/sharer.php', {
            u: this.url()
          });
        } else {
          window.FB.ui({
            method: 'feed',
            name: this.title(),
            link: this.url(),
            picture: this.image(),
            caption: this.caption(),
            description: this.description()
          });
        }
      },
      twitter: function twitter() {
        openPopup('https://twitter.com/intent/tweet', {
          text: this.description(),
          url: this.url()
        });
      },
      googlePlus: function googlePlus() {
        openPopup('https://plus.google.com/share', {
          url: this.url()
        });
      },
      pinterest: function pinterest() {
        openPopup('https://www.pinterest.com/pin/create/button', {
          url: this.url(),
          media: this.image(),
          description: this.description()
        });
      },
      linkedin: function linkedin() {
        openPopup('https://www.linkedin.com/shareArticle', {
          mini: 'true',
          url: this.url(),
          title: this.title(),
          summary: this.description()
        });
      },

      reddit: function reddit() {
        openPopup('http://www.reddit.com/submit', {
          url: this.url(),
          title: this.title()
        });
      },

      whatsapp: function whatsapp() {
        openPopup('whatsapp://send', {
          text: this.description + ' ' + this.url()
        });
      }
    },

    actions: {
      share: function share(network) {
        var fn = this.shareActions[network.name];
        if (fn) {
          fn.call(this, network);
        }
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var facebookConfig = this.get('networks').find(function (n) {
        return n.name === 'facebook';
      });
      if (facebookConfig && facebookConfig.appId != null) {
        injectFacebookSdk(facebookConfig.appId);
      }
    },

    url: function url() {
      return window.location.href;
    },

    title: function title() {
      var content = undefined;
      if (content = document.querySelector('meta[property="og:title"]') || document.querySelector('meta[name="twitter:title"]')) {
        return content.getAttribute('content');
      } else if (content = document.querySelector('title')) {
        return content.textContent || content.innerText;
      }
    },

    image: function image() {
      var content = undefined;
      if (content = document.querySelector('meta[property="og:image"]') || document.querySelector('meta[name="twitter:image"]')) {
        return content.getAttribute('content');
      }
    },

    caption: function caption() {},

    description: function description() {
      var content = undefined;
      if (content = document.querySelector('meta[property="og:description"]') || document.querySelector('meta[name="twitter:description"]') || document.querySelector('meta[name="description"]')) {
        return content.getAttribute('content');
      } else {
        return '';
      }
    }
  });

  function expandNetworkConfig(entry) {
    if (typeof entry === 'string') {
      return { name: entry };
    } else {
      return entry;
    }
  }

  function injectFacebookSdk(appId) {
    var bodyTag = (0, _jquery['default'])(document).find('body')[0];

    if (!window.FB && !bodyTag.querySelector('#fb-root')) {
      var script = document.createElement('script');
      script.text = 'window.fbAsyncInit=function(){FB.init({appId:\'' + appId + '\',status:true,xfbml:true})};(function(e,t,n){var r,i=e.getElementsByTagName(t)[0];if (e.getElementById(n)){return}r=e.createElement(t);r.id=n;r.src=\'//connect.facebook.net/en_US/all.js\';i.parentNode.insertBefore(r,i)})(document,\'script\',\'facebook-jssdk\');';

      var fbRoot = document.createElement('div');
      fbRoot.id = 'fb-root';

      bodyTag.appendChild(fbRoot);
      bodyTag.appendChild(script);
    }
  }

  function openPopup(target, params) {

    var qs = Object.keys(params).filter(function (k) {
      return params[k];
    }).map(function (k) {
      return k + '=' + toRFC3986(params[k]);
    }).join('&');

    if (qs) {
      target = target + '?' + qs;
    }

    var popup = {
      width: 500,
      height: 350
    };

    popup.top = screen.height / 2 - popup.height / 2;
    popup.left = screen.width / 2 - popup.width / 2;

    window.open(target, 'targetWindow', '\n          toolbar=no,\n          location=no,\n          status=no,\n          menubar=no,\n          scrollbars=yes,\n          resizable=yes,\n          left=' + popup.left + ',\n          top=' + popup.top + ',\n          width=' + popup.width + ',\n          height=' + popup.height + '\n        ');
  }

  function toRFC3986(s) {
    return encodeURIComponent(s).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }
});